<template>
  <div>
    <nav class="navbar-expand-lg fixed-top navbar-light bg-light">
      <div class="container-fluid">
        <div class="row">
          <!-- Logo + Botones --->
          <div class="col-lg-4">
            <router-link to="/" class="nav-link active">
              <div id="logo" class="row">
                <div class="col-lg-12 mb-4 mt-2">
                  <img src="img/logo.svg" alt="Logo Evoluciona" />
                </div>
                <div class="col-lg-4">
                  <router-link to="/home-subsidio"
                    ><img
                      class="img-boton"
                      src="img/btn/btn_subsidio.svg"
                      alt="subsidio"
                  /></router-link>
                </div>
                <div class="col-lg-4">
                  <router-link to="/home-hogar"
                    ><img
                      class="img-boton"
                      src="img/btn/btn_hogar.svg"
                      alt="hogar"
                  /></router-link>
                </div>
                <div class="col-lg-4">
                  <router-link to="/home-empresas"
                    ><img
                      class="img-boton"
                      src="img/btn/btn_empresa.svg"
                      alt="empresa"
                  /></router-link>
                </div>
              </div>
            </router-link>
          </div>
          <!-- Logo + Botones --->

          <!-- MENU --->
          <div class="col-lg-4 pt-4 text-center">
            <ul
              class="navbar-nav navbar-center pt-4 d-flex justify-content-center"
            >
              <li class="nav-item">
                <router-link to="/nosotros" class="nav-link txt-nav active"
                  >nosotros
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  to="/preguntas-frecuentes"
                  class="nav-link txt-nav active"
                  >preguntas frecuentes
                </router-link>
              </li>

              <li class="nav-item dropdown">
                <a
                  id="navbarDropdown"
                  class="nav-link txt-nav dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  más
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <router-link
                      to="/asesoramos"
                      class="nav-link dropdown-item txt-nav"
                      >Te asesoramos
                    </router-link>
                  </li>
                  <li>
                    <button
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#dividendo"
                      class="nav-link dropdown-item txt-nav"
                    >
                      Portabilidad
                    </button>
                  </li>
                  <li>
                    <router-link
                      to="/licitaciones"
                      class="nav-link dropdown-item txt-nav"
                      >Licitaciones
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- MENU --->
          <!-- BOTONES --->

          <div class="col-lg-2 d-flex flex-column justify-content-center">
            <router-link to="/formularios"
              ><img src="img/btn/btn_form.svg" class="img-form" alt=""
            /></router-link>

            <a href="/medios-de-pago"
              ><img class="img-form" src="img/btn/btn_pagar.svg" alt=""
            /></a>
          </div>

          <div class="col-lg-1 d-flex flex-column justify-content-center">
            <a
              class="text-decoration-none"
              href="https://sucursal.hipotecariaevoluciona.cl"
            >
              <div class="virtual-branch">
                <img src="img/virtual-branch.svg" alt="" />
                <p class="text-blue">mi portal</p>
              </div>
            </a>
          </div>

          <!-- BOTONES --->
        </div>
      </div>
    </nav>

    <!-- Modal -->

    <div
      id="dividendo"
      class="modal fade modal-bg-simulador"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-body-simulador">
          <div class="modal-body-simulador">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="bi bi-x-lg close_color"></i>
            </button>
            <img src="img/pop_portabilidad.svg" alt="Portabilidad" />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
export default {
  name: 'Menu',
};
</script>
