<template>
  <div>
    <div v-for="(item, index) in carouselItems" :key="index">
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            :src="item.imgSrc"
            :alt="item.alt"
        /></template>
      </b-carousel-slide>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    carouselItems: {
      type: Array,
      default: () => [
        {
          alt: null,
          imgSrc: null,
        },
      ],
    },
  },
};
</script>
