export const itemsDesktop = [
  {
    alt: 'Métodos de Pago',
    imgSrc: 'img/carousel/banner-pac-desktop.jpg',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_1980x600.jpg',
  },
  {
    alt: 'Crédito Hogar',
    imgSrc: 'img/carousel/banners-new-hogar_1980x600.jpg',
  },
];

export const itemsMobile = [
  {
    alt: 'Métodos de Pago',
    imgSrc: 'img/carousel/banner-pac-mobile.jpg',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_600x900.jpg',
  },
  {
    alt: 'Crédito Hogar',
    imgSrc: 'img/carousel/banners-movil-new-hogar_600x900.jpg',
  },
];
